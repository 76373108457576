/* eslint no-param-reassign: off */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import httpClient from 'utilities/httpClient';

export interface Once {
  id: number;
  name: string;
  timeCreated?: number;
}

const oncesAdapter = createEntityAdapter<Once>({
  sortComparer: (a, b) => (a.id > b.id ? -1 : 1),
});

export const fetchOnces = createAsyncThunk(
  'once/fetchAll',
  async () => {
    const response = await httpClient.post(`/service/rest/once`);
    return response.data;
  }
);

export const modifyOnce = createAsyncThunk(
  'once/modifyOneOnce',
  async once => {
    const response = await httpClient.post(
      '/service/rest/once/modify',
      once
    );
    return response.data;
  }
);

export const modifyListOnces = createAsyncThunk(
  'once/modifyList',
  async (onces: Once[]) => {
    const response = await httpClient.post(
      '/service/rest/once/modifyList',
      onces
    );
    return response.data;
  }
);

export const deleteOnce = createAsyncThunk(
  'once/deleteOneOnce',
  async (id: number) => {
    await httpClient.delete(`/service/rest/once/${id}/delete`);
    return id;
  }
);

const oncesSlice = createSlice({
  name: 'once',
  initialState: oncesAdapter.getInitialState({
    // 'idle' | 'loading' | 'error' | 'succeeded' | 'updating' | 'deleting'
    status: 'idle',
    error: {},
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      // fetch all
      .addCase(fetchOnces.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchOnces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        oncesAdapter.setAll(state, action.payload.data);
      })
      .addCase(fetchOnces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // create / modify one
      .addCase(modifyOnce.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyOnce.fulfilled, (state, action) => {
        state.status = 'succeeded';
        oncesAdapter.upsertOne(state, action.payload);
      })
      // modify list onces
      .addCase(modifyListOnces.pending, state => {
        state.status = 'updating';
      })
      .addCase(modifyListOnces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        oncesAdapter.upsertMany(state, action.payload);
      })
      .addCase(modifyListOnces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      // deleting
      .addCase(deleteOnce.pending, state => {
        state.status = 'deleting';
      })
      .addCase(deleteOnce.fulfilled, (state, action) => {
        state.status = 'succeeded';
        oncesAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteOnce.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export default oncesSlice.reducer;

// memoised selectors
export const {
  selectAll: selectAllOnces,
  selectById: selectOnceById,
} = oncesAdapter.getSelectors<RootState>(state => state.onces);
